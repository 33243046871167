import { render, staticRenderFns } from "./ArtworksField.vue?vue&type=template&id=634194f9&scoped=true"
import script from "./ArtworksField.vue?vue&type=script&lang=js"
export * from "./ArtworksField.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "634194f9",
  null
  
)

export default component.exports