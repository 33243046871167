import Vue from "vue"
import Vuex from "vuex"
import axios from "axios"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    showMenu: null,
    session: null,
    roles: [],
    alerts: [],
    site: null,
    sites: [],
    sitesMap: {},
    currentSite: {
      id: null,
      regions: []
    }
  },
  mutations: {
    setSites(state, sites) {
      state.sites = sites
      let sitesMap = {}
      sites.forEach(site => {
        sitesMap[site.id] = site
      })
      state.sitesMap = sitesMap
    },
    setCurrentSiteId(state, siteId) {
      state.currentSite.id = siteId
    },
    setCurrentSiteRegions(state, regions) {
      state.currentSite.regions = regions
    },
    toggleMenu(state) {
      state.showMenu = !state.showMenu
    },
    setSession(state, session) {
      state.session = session
    },
    setRoles(state, roles) {
      state.roles = roles
    },
    addAlert(state, alert) {
      state.alerts.push(alert)
      setTimeout(() => {
        state.alerts.splice(0, 1)
      }, 5000)
    }
  },
  getters: {
    retailersPerTerritory: state => {
      let result = {}

      state.currentSite.regions.forEach(region => {
        ;(region.territories || []).forEach(territory => {
          result[territory] = region.retailers
        })
      })

      return result
    }
  },
  actions: {
    loadSites({ commit }) {
      axios.get("/api/sites").then(response => {
        commit("setSites", response.data.data || [])
      })
    },
    loadCurrentSite({ state, commit }, payload) {
      let siteId = typeof payload === "string" ? payload : payload.siteId
      let force = typeof payload === "object" ? !!payload.force : false

      if (state.currentSite.id === siteId && !force) return

      axios.get(`/api/site/${siteId}/regions`).then(response => {
        commit("setCurrentSiteRegions", response.data.data || [])
      })

      commit("setCurrentSiteId", siteId)
    }
  }
})
