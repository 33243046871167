<template>
  <div>
    <v-layout
      v-for="(_, index) in site.territory_mapping"
      :key="index"
      row
      wrap
    >
      <v-col cols="5" sm="3" class="py-0">
        <v-text-field
          v-model="site.territory_mapping[index]['from']"
          label="From"
          hint="Required: Territory detected by the system"
          :rules="[rules.required, rules.country_code]"
        />
      </v-col>
      <v-col cols="5" sm="3" class="py-0">
        <v-text-field
          v-model="site.territory_mapping[index]['to']"
          label="To"
          hint="Required: Territory which will be used instead"
          :rules="[rules.required, rules.country_code]"
        />
      </v-col>
      <v-col cols="1" class="py-0">
        <v-btn fab small color="red" @click="deleteMapping(index)">
          <v-icon color="white">delete</v-icon>
        </v-btn>
      </v-col>
    </v-layout>
    <v-btn fab small color="primary" class="mt-2" @click="addLink">
      <v-icon>add</v-icon>
    </v-btn>
  </div>
</template>

<script>
import rules from "../rules"
import { Site, TerritoryMap } from "@/models/site"

export default {
  props: {
    site: {
      type: Object,
      default: new Site()
    }
  },
  data() {
    return {
      rules: rules
    }
  },

  methods: {
    addLink() {
      if (!this.site.territory_mapping) this.site.territory_mapping = []
      this.site.territory_mapping.push(new TerritoryMap())
    },
    deleteMapping(index) {
      if (confirm("Are you sure?")) this.site.territory_mapping.splice(index, 1)
    }
  }
}
</script>

<style scoped>
table {
  width: 100%;
}
</style>
