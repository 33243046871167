<template>
  <v-layout column>
    <v-flex xs12 class="toolbar">
      <v-toolbar dense flat>
        <v-toolbar-title class="title">
          {{ title }}
        </v-toolbar-title>
        <v-toolbar-items>
          <slot name="actions" />
        </v-toolbar-items>
      </v-toolbar>
    </v-flex>
    <v-flex class="content">
      <slot />
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: "Page",
  props: {
    title: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.toolbar {
  background-color: white;
  border-bottom: 1px solid #d3d3d3;
}

.title {
  padding-right: 50px;
  padding-left: 4px;
}

.content {
  padding: 0 20px;
}
</style>
