<template>
  <v-container fluid>
    <v-row>
      <v-col cols="4">
        <v-select
          v-model="metadataIndex"
          label="Localization"
          :items="locales"
        ></v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-checkbox
          v-model="value[metadataIndex].override"
          label="Override"
        ></v-checkbox>

        <v-expansion-panels v-model="panels" multiple>
          <v-expansion-panel
            v-for="(contentItem, cidx) in value[metadataIndex].content_items"
            :key="`locale-${cidx}`"
          >
            <v-expansion-panel-header>
              <strong
                >{{ contentItem.title }} - {{ contentItem.content_id }}</strong
              >
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <v-text-field
                v-model="value[metadataIndex].content_items[cidx].title"
                label="Title"
                :disabled="!value[metadataIndex].override"
              ></v-text-field>

              <Multi
                v-model="value[metadataIndex].content_items[cidx].ratings"
                kind="Rating"
                label="Ratings"
                :readonly="!value[metadataIndex].override"
                :new-element="() => new Rating()"
              >
                <template #item="{ index }">
                  <RatingField
                    v-model="
                      value[metadataIndex].content_items[cidx].ratings[index]
                    "
                    :disabled="!value[metadataIndex].override"
                  />
                </template>
              </Multi>

              <h2>Runtime</h2>
              <RuntimeField
                v-model="value[metadataIndex].content_items[cidx].runtime"
                :disabled="!value[metadataIndex].override"
              />

              <GenresField
                v-model="value[metadataIndex].content_items[cidx].genres"
                :readonly="!value[metadataIndex].override"
              />

              <ArtworksField
                v-model="value[metadataIndex].content_items[cidx].artworks"
                :readonly="!value[metadataIndex].override"
                :required="false"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Multi from "./Multi"
import RatingField from "./RatingField"
import { Rating } from "@/models/content"
import ArtworksField from "./ArtworksField"
import GenresField from "./GenresField"
import RuntimeField from "./RuntimeField"

export default {
  name: "SiteOfferMetadataForm",
  components: { RuntimeField, GenresField, ArtworksField, RatingField, Multi },
  props: {
    value: {
      type: Array,
      required: true
    },
    siteId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      Rating,
      metadataIndex: 0,
      panels: [0]
    }
  },
  computed: {
    locales() {
      let result = []
      for (let i = 0; i < this.value.length; i++) {
        result.push({
          text: `${this.value[i].language}-${this.value[i].territory}`,
          value: i
        })
      }
      return result
    }
  }
}
</script>
