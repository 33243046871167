<template>
  <div>
    <span class="label">{{ label }}</span>
    <div v-if="filePath" class="artwork">
      <img :src="artworkPreview" alt="artwork preview" />
    </div>
    <div v-else class="error--text"><i>No artwork set</i></div>

    <div class="mb-4">
      <v-input
        type="hidden"
        :value="value"
        :rules="required ? [rules.required] : []"
      />
      <v-btn :disabled="readonly" @click="$refs.file.click()">
        Upload Artwork
      </v-btn>
      &nbsp;
      <v-btn
        v-if="value && !required"
        color="red"
        text
        :disabled="readonly"
        @click="clear"
      >
        Clear Artwork
      </v-btn>
    </div>
    <form method="post" enctype="multipart/form-data" class="hidden">
      <input
        ref="file"
        type="file"
        name="file"
        class="hidden"
        @change="uploadFile"
      />
    </form>
  </div>
</template>

<script>
import rules from "../rules"

export default {
  name: "ArtworkPicker",
  props: {
    value: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    hint: {
      type: String,
      default: ""
    },
    readonly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      inProgress: false,
      rules: rules
    }
  },
  computed: {
    filePath: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      }
    },
    artworkPreview() {
      let url

      try {
        url = new URL(this.filePath)
      } catch (e) {
        return this.filePath
      }

      if (!url.hostname.endsWith("imgix.net")) return this.filePath

      let separator = url.search ? "&" : "?"

      return `${this.filePath}${separator}w=240`
    }
  },
  methods: {
    clear() {
      if (confirm("Are you sure?")) {
        this.filePath = ""
      }
    },
    uploadFile() {
      this.inProgress = true

      let formData = new FormData()
      formData.append("file", this.$refs.file.files[0])

      this.$http
        .post("api/cdn/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          if (response.data.status === "ok") {
            this.filePath = response.data.data.url
            this.showSuccess("Artwork uploaded successfully")
          }
          this.inProgress = false
        })
    }
  }
}
</script>

<style scoped>
.hidden {
  opacity: 0;
  height: 0;
  width: 0;
}
.label {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
}
.artwork {
  padding: 10px 0;
}

.artwork img {
  width: 240px;
  max-width: 100%;
  box-shadow: 2px 2px 5px grey;
}
</style>
