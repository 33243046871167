<template>
  <div>
    <v-layout v-for="(_, index) in properties" :key="index" row wrap>
      <v-col cols="12" sm="2" class="py-0">
        <v-select
          v-model="properties[index].type"
          :items="propertyTypes"
          :rules="[rules.required]"
          label="Type"
          single-line
        />
      </v-col>
      <v-col cols="12" sm="2" class="py-0">
        <v-text-field
          v-model="properties[index].name"
          label="Name"
          :rules="[rules.required, rules.spacelessAlpha]"
        />
      </v-col>
      <v-col cols="12" sm="4" class="py-0">
        <v-text-field
          v-model="properties[index].help"
          label="Description"
          :rules="[rules.required]"
        />
      </v-col>
      <v-col cols="12" sm="3" class="py-0">
        <v-text-field
          v-model="properties[index].default"
          label="Default Value (optional)"
        />
      </v-col>
      <v-col cols="12" sm="1" class="py-0">
        <v-btn small dark fab color="red" @click="removeProperty(index)">
          <v-icon>delete</v-icon>
        </v-btn>
        <v-divider v-if="$vuetify.breakpoint.xsOnly" class="my-4" />
      </v-col>
    </v-layout>
    <v-btn small fab color="primary" class="mt-2" @click="addProperty">
      <v-icon>add</v-icon>
    </v-btn>
  </div>
</template>

<script>
import rules from "../rules"
import Property from "../models/property"

export default {
  name: "Properties",
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      properties: this.value,
      propertyTypes: [
        { text: "Text", value: "text" },
        { text: "File", value: "file" },
        { text: "Color", value: "color" },
        { text: "Artwork", value: "artwork" }
      ],
      rules: rules
    }
  },
  watch: {
    properties(newValue) {
      this.$emit("input", newValue)
    }
  },
  methods: {
    addProperty() {
      if (!this.properties) this.properties = []
      this.properties.push(new Property())
    },
    removeProperty(index) {
      if (confirm("Are you sure?")) {
        this.properties.splice(index, 1)
      }
    }
  }
}
</script>
