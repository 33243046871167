var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.site)?_c('Page',{attrs:{"title":((_vm.site.name) + " - Offers")}},[_c('v-text-field',{attrs:{"label":"MDEO Offer ID"},on:{"change":_vm.fetch},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}},[_c('div',{attrs:{"slot":"append"},slot:"append"},[_c('v-btn',{staticClass:"mb-2",attrs:{"small":"","color":"info"},on:{"click":_vm.fetch}},[_vm._v("Search")])],1)]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.offers,"page":_vm.page,"server-items-length":_vm.totalItems,"items-per-page":10,"hide-default-footer":""},on:{"update:page":[function($event){_vm.page=$event},_vm.fetch],"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{
          name: 'site-offer-view',
          params: { siteId: _vm.siteId, offerId: item.id }
        }}},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.territories",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.territories.join(", "))+" ")]}}],null,true)}),_c('div',{staticClass:"text-center py-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":11},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }