class MDEOConfig {
  constructor() {
    this.environment = "production"
    this.content_provider_ids = []
    this.key = ""
    this.secret = ""
  }
}

class GPCBConfig {
  constructor() {
    this.key = ""
    this.secret = ""
    this.organization = ""
  }
}

class CaptchaConfig {
  constructor() {
    this.threshold = 5
    this.window = 300
    this.site_key = ""
    this.secret = ""
  }
}

class TerritoryMap {
  constructor() {
    this.from = ""
    this.to = ""
  }
}

class Site {
  constructor() {
    this.id = null
    this.name = ""
    this.owner = ""
    this.locale = []
    this.default_territory = null
    this.allow_territory_override = false
    this.territory_mapping = []
    this.mdeo_config = new MDEOConfig()
    this.gpcb_config = new GPCBConfig()
    this.captcha_config = new CaptchaConfig()
    this.active = false
    this.history = []
    this.region_properties = []
    this.offer_properties = []
    this.promo_properties = []
  }
}

export { Site, TerritoryMap }
