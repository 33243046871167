<template>
  <Page v-if="site" :title="`${site.name} - Settings`">
    <template slot="actions">
      <v-btn small text color="primary" :disabled="!valid" @click="submit">
        <v-icon small left>save</v-icon>
        Save
      </v-btn>
    </template>

    <v-form ref="form" v-model="valid" lazy-validation class="pb-5">
      <div v-if="!valid" class="my-5 error--text">
        Form validation failed - please check all fields for errors.
      </div>

      <h2>General</h2>

      <v-text-field
        v-model="site.id"
        label="Site ID (read only)"
        readonly
      ></v-text-field>

      <v-text-field
        v-model="site.name"
        label="Name"
        hint="Required: The name of the site"
        :rules="[rules.required]"
      ></v-text-field>

      <v-text-field
        v-model="site.default_territory"
        label="Default territory"
        hint="Required: Default territory"
        :rules="[rules.required, rules.country_code]"
      ></v-text-field>

      <v-switch
        v-model="site.allow_territory_override"
        label="Allow territory detection override (using `cc` query parameter)"
      ></v-switch>

      <v-switch v-model="site.active" label="Active"></v-switch>

      <h2>MDEO Credentials</h2>

      <v-select
        v-model="site.mdeo_config.environment"
        label="Environment"
        :items="mdeoEnvironments"
        :rules="[rules.required]"
      ></v-select>

      <v-text-field
        v-model="contentProviderIds"
        label="Content Provider IDs"
        hint="Required: Content Provider IDs"
        :rules="[rules.required]"
      ></v-text-field>

      <v-text-field
        v-model="site.mdeo_config.key_id"
        label="Key ID"
        hint="Required: Key ID"
        :rules="[rules.required]"
      ></v-text-field>

      <v-text-field
        v-model="site.mdeo_config.secret"
        type="password"
        label="Key"
        hint="Key - set to overwrite"
      ></v-text-field>

      <h2>GPCB Credentials</h2>

      <v-text-field
        v-model="site.gpcb_config.organization"
        label="Organization"
      ></v-text-field>

      <v-text-field
        v-model="site.gpcb_config.key_id"
        label="Key ID"
      ></v-text-field>

      <v-textarea
        v-model="site.gpcb_config.secret"
        label="Key"
        hint="Key - set to overwrite"
      ></v-textarea>

      <h2>Captcha Credentials</h2>

      <v-text-field
        v-model="site.captcha_config.site_key"
        label="Site Key"
        hint="Required: Site Key"
        :rules="[rules.required]"
      ></v-text-field>

      <v-text-field
        v-model="site.captcha_config.secret"
        type="password"
        label="Secret Key"
        hint="Secret Key - set to overwrite"
      ></v-text-field>

      <v-text-field
        v-model="site.captcha_config.window"
        type="number"
        label="Window"
        hint="Required: Length of time to store user's IP for each failed action"
        :rules="[rules.required]"
      ></v-text-field>

      <v-text-field
        v-model="site.captcha_config.threshold"
        type="number"
        label="Threshold"
        hint="Required: Number of failed actions allowed before CAPTCHA is required"
        :rules="[rules.required]"
      ></v-text-field>

      <h2>Region properties</h2>
      <PropertiesDefinition
        v-model="site.region_properties"
      ></PropertiesDefinition>
      <v-divider class="mt-5" />

      <h2>Offer properties</h2>
      <PropertiesDefinition
        v-model="site.offer_properties"
      ></PropertiesDefinition>
      <v-divider class="mt-5" />

      <h2>Promotion properties</h2>
      <PropertiesDefinition
        v-model="site.promo_properties"
      ></PropertiesDefinition>
      <v-divider class="mt-5" />

      <h2>Territory Mapping</h2>
      <TerritoryMapping :site="site" />
      <v-divider class="mt-5" />
    </v-form>
  </Page>
</template>

<script>
import rules from "../rules"
import TerritoryMapping from "../components/TerritoryMapping"
import PropertiesDefinition from "../components/PropertiesDefinition"
import Page from "../components/Page"

export default {
  components: { Page, PropertiesDefinition, TerritoryMapping },
  props: {
    siteId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      site: null,
      contentProviderIds: "",
      valid: false,
      rules: rules,
      mdeoEnvironments: [
        {
          text: "Production",
          value: "api.mdeo.co"
        },
        {
          text: "Sandbox",
          value: "sandbox-api.mdeo.co"
        },
        {
          text: "Development",
          value: "dev-api.mdeo.co"
        }
      ]
    }
  },
  watch: {
    siteId: "refresh"
  },
  mounted() {
    this.refresh()
  },
  methods: {
    refresh() {
      this.$http.get(`api/site/${this.siteId}`).then(response => {
        let contentProviderIds =
          response.data.data.mdeo_config.content_provider_ids || []
        this.$set(this, "site", response.data.data)
        this.$set(this, "contentProviderIds", contentProviderIds.join(","))
      })
    },
    submit() {
      if (!this.$refs.form.validate()) return

      this.site.captcha_config.window = parseInt(
        this.site.captcha_config.window
      )
      this.site.captcha_config.threshold = parseInt(
        this.site.captcha_config.threshold
      )
      this.site.mdeo_config.content_provider_ids = this.contentProviderIds
        .replace(/\s/g, "")
        .split(",")
      this.$http.put(`api/site/${this.siteId}`, this.site).then(response => {
        if (response.status === 204) {
          this.showSuccess("The site has been updated.")
          this.$store.dispatch("loadSites")
        }
      })
    }
  }
}
</script>

<style scoped>
h2 {
  margin-top: 20px;
  margin-bottom: 10px;
}
</style>
