<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="200px"
    >
      <template #activator="{ on }">
        <v-text-field :value="value" readonly :label="label">
          <div slot="prepend-inner" :style="style" class="colorBox" v-on="on" />
        </v-text-field>
      </template>

      <color-picker v-model="color" :disable-alpha="true" />
    </v-menu>
  </div>
</template>

<script>
import { Chrome } from "vue-color"

export default {
  name: "ColorPicker",
  components: {
    "color-picker": Chrome
  },
  props: {
    value: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      menu: false,
      color: {
        hex: this.value || "#000000"
      }
    }
  },
  computed: {
    style() {
      return `background-color: ${this.value || "#000000"}`
    }
  },
  watch: {
    color(value) {
      this.$emit("input", value.hex)
    }
  }
}
</script>

<style scoped>
.colorBox {
  width: 40px;
  height: 20px;
  border: 1px solid lightgrey;
  border-radius: 3px;
  cursor: pointer;
}
</style>
