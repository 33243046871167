<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template #activator="{ on }">
      <v-text-field
        :label="label"
        :value="dateTime"
        readonly
        clearable
        prepend-icon="event"
        v-on="on"
        @click:clear="clear"
        @change="submit"
      ></v-text-field>
    </template>

    <div v-show="step === 1">
      <v-date-picker v-model="date">
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="reset()">Cancel</v-btn>
        <v-btn text color="primary" @click="step = 2">OK</v-btn>
      </v-date-picker>
    </div>

    <div v-show="step === 2">
      <v-time-picker v-model="time">
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="reset()">Cancel</v-btn>
        <v-btn text color="primary" @click="submit()">OK</v-btn>
      </v-time-picker>
    </div>
  </v-menu>
</template>

<script>
export default {
  name: "DateTimePicker",
  props: {
    value: {
      type: [String, Number],
      default: ""
    },
    label: {
      type: String,
      default: ""
    }
  },
  data() {
    const tzOffset = new Date().getTimezoneOffset() * 60
    const localDateTime = new Date((this.value - tzOffset) * 1000)

    return {
      menu: false,
      step: 1,
      date: this.value > 0 ? localDateTime.toISOString().slice(0, 10) : "",
      time:
        this.value > 0 ? localDateTime.toISOString().slice(11, 16) : "00:00:00"
    }
  },
  computed: {
    dateTime() {
      if (this.value > 0) return new Date(this.value * 1000).toLocaleString()

      return ""
    }
  },
  methods: {
    reset() {
      this.menu = false
      this.step = 1
    },
    clear() {
      this.date = ""
      this.time = "00:00:00"
      this.$emit("input", 0)
    },
    submit() {
      const value = new Date(`${this.date}T${this.time}`) / 1000
      this.$emit("input", value)
      this.reset()
    }
  }
}
</script>
