export default {
  optional: true,
  required: value => !!value || "Required.",
  country_code: value => !!/^[A-Z]{2}$/.exec(value) || "Invalid country code.",
  territories: value => {
    if (typeof value !== "object") return "Required."

    try {
      value.forEach(cc => {
        if (typeof cc === "string" && !/^[A-Z]{2}$/.exec(cc))
          throw `Invalid territory code '${cc}'.`
      })
    } catch (err) {
      return err.toString()
    }

    return true
  },
  language: value => {
    if (typeof value !== "string" || !/^[a-z]{2}$/.exec(value))
      return "Invalid language code"

    return true
  },
  languages: value => {
    if (!value) return true

    try {
      value.forEach(lc => {
        if (typeof lc === "string" && !/^[a-z]{2}$/.exec(lc))
          throw `Invalid language code '${lc}'.`
      })
    } catch (err) {
      return err.toString()
    }

    return true
  },
  spacelessAlpha: value => {
    if (typeof value === "string" && !/^[A-Za-z_]+$/.exec(value)) {
      return 'Invalid value - only lower and upper case characters and "_" are allowed.'
    }
    return true
  }
}
