<template>
  <Multi
    v-model="artworks"
    kind="Artwork"
    label="Artworks"
    :new-element="() => new Artwork()"
    :readonly="readonly"
  >
    <template #item="{ index }">
      <v-card class="pa-5 my-2">
        <ArtworkPicker
          v-model="artworks[index].url"
          :readonly="readonly"
          :required="required"
        />
        <v-autocomplete
          v-model="artworks[index].type"
          label="Type"
          class="mt-0"
          :items="artworkTypes"
          :rules="[rules.required]"
          :disabled="readonly"
        />
      </v-card>
    </template>
  </Multi>
</template>

<script>
import Multi from "./Multi"
import ArtworkPicker from "./ArtworkPicker"
import { Artwork } from "@/models/content"
import rules from "../rules"

export default {
  name: "ArtworksField",
  components: { ArtworkPicker, Multi },
  props: {
    value: {
      type: Array,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      Artwork,
      rules: rules,
      artworkTypes: ["poster", "banner", "hero"]
    }
  },
  computed: {
    artworks: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      }
    }
  }
}
</script>

<style scoped></style>
