<template>
  <div class="text-xs-center">
    <v-text-field v-model="filePath" :label="label" :hint="hint" clearable>
      <div slot="append">
        <v-btn small class="mb-2" @click="openExplorer">Browse</v-btn>
      </div>
    </v-text-field>

    <v-dialog v-model="fileExplorerActive" width="600">
      <v-card>
        <v-card-title primary-title class="headline grey lighten-2">
          <v-flex class="pl-3" row justify-space-between>
            <div>File explorer</div>
            <div>
              <button @click="addDirectory">
                <v-icon>create_new_folder</v-icon>
              </button>
            </div>
          </v-flex>
        </v-card-title>
        <v-card-text>
          <v-breadcrumbs large>
            <v-breadcrumbs-item href="#" @click="goTo(0)">
              <v-icon>home</v-icon>
            </v-breadcrumbs-item>

            <template v-for="(path, index) in browsePath">
              <v-breadcrumbs-divider :key="`bc-div-${index}`">
                /
              </v-breadcrumbs-divider>
              <v-breadcrumbs-item
                :key="`bc-item-${index}`"
                href="#"
                @click="goTo(index + 1)"
              >
                {{ path.substr(0, path.length - 1) }}
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>

          <v-data-table
            :headers="headers"
            :items="browseFiles"
            class="elevation-1"
          >
            <template slot="item" slot-scope="props">
              <tr>
                <td>
                  <a class="fileLink" @click="select(props.item)">
                    {{ props.item.name }}
                  </a>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="mx-4">
          <form method="post" enctype="multipart/form-data" class="upload-form">
            Select file to upload:
            <input
              id="file"
              ref="file"
              type="file"
              name="file"
              class="caption my-2"
              @change="handleFileUpload"
            />
            <v-btn class="my-2" small :disabled="inProgress" @click="uploadFile"
              >Upload
            </v-btn>
          </form>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "FilePicker",
  props: {
    value: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    hint: {
      type: String,
      default: ""
    },
    basePath: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      browsePath: [],
      browseFiles: [],
      inProgress: false,
      headers: [{ text: "Name", value: "name" }],
      fileExplorerActive: false,
      file: ""
    }
  },
  computed: {
    filePath: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      }
    },
    currentPath() {
      return `${this.basePath || ""}/${this.browsePath.join("/")}`
        .replace("//", "/")
        .replace(/^\/*/, "")
    }
  },
  methods: {
    goTo(index) {
      if (index < this.browsePath.length) {
        this.browsePath.splice(index)
        this.getFiles()
      }
    },
    addDirectory() {
      let name = prompt("Provide a name for the new directory.")
      if (name) {
        this.browsePath.push(`${name}/`)
        this.getFiles()
      }
    },
    handleFileUpload() {
      this.inProgress = false
      this.file = this.$refs.file.files[0]
    },
    select(file) {
      if (file.name.endsWith("/")) {
        this.browsePath.push(file.name)
        this.getFiles()
      } else {
        this.filePath = file.url
        this.fileExplorerActive = false
      }
    },
    openExplorer() {
      this.getFiles()
      this.fileExplorerActive = true
    },
    getFiles() {
      this.$http.get(`api/cdn/list?path=${this.currentPath}`).then(response => {
        this.$set(this, "browseFiles", response.data.data || [])
      })
    },
    uploadFile() {
      this.inProgress = true

      let formData = new FormData()
      formData.append("file", this.file)
      formData.append("path", `${this.currentPath}/${this.file.name}`)

      this.$http
        .post("api/cdn/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          if (response.data.status === "ok") {
            this.filePath = response.data.data.url
            this.fileExplorerActive = false
            this.showSuccess(`File ${this.file.name} uploaded successfully`)
          }
          this.inProgress = false
        })
    }
  }
}
</script>

<style scoped>
.fileLink:hover {
  text-decoration: underline;
}
.upload-form {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 600px) {
  .upload-form {
    flex-direction: column;
    align-items: stretch;
  }
}
</style>
