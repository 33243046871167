<template>
  <Page v-if="$store.state.session" title="Home">
    <v-card class="px-0 mt-4" flat>
      <h1>Welcome, {{ $store.state.session.given_name }}.</h1>
      <p>
        To manage your site, select appropriate option from the menu on the
        left.
      </p>
    </v-card>
  </Page>
</template>
<script>
import Page from "../components/Page"
export default {
  components: { Page }
}
</script>
