import Vue from "vue"
import App from "./App.vue"

import router from "./router"
import store from "./store"
import axios from "axios"

import Vuetify from "vuetify"
import Datetime from "vue-datetime"

import "vuetify/dist/vuetify.min.css"
import "material-design-icons-iconfont/dist/material-design-icons.css"
import "vue-datetime/dist/vue-datetime.css"

Vue.use(Vuetify)

const vuetify = new Vuetify({})

Vue.use(Datetime)

Vue.config.productionTip = false

axios.defaults.baseURL = process.env.VUE_APP_API_URL || "/"
axios.defaults.withCredentials = true

Vue.prototype.$http = axios

Vue.prototype.showAlert = function (level, message) {
  // level can be: success, info, warning, error
  store.commit("addAlert", {
    id: Date.now(),
    level: level,
    message: message
  })
}
Vue.prototype.showSuccess = message =>
  Vue.prototype.showAlert("success", message)
Vue.prototype.showWarning = message =>
  Vue.prototype.showAlert("warning", message)
Vue.prototype.showError = message => Vue.prototype.showAlert("error", message)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app")
