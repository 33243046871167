<template>
  <Page v-if="site" :title="`${site.name} - Offers`">
    <v-text-field v-model="query" label="MDEO Offer ID" @change="fetch">
      <div slot="append">
        <v-btn small color="info" class="mb-2" @click="fetch">Search</v-btn>
      </div>
    </v-text-field>
    <v-data-table
      :headers="headers"
      :items="offers"
      :page.sync="page"
      :server-items-length="totalItems"
      :items-per-page="10"
      hide-default-footer
      class="elevation-1"
      @update:page="fetch"
      @page-count="pageCount = $event"
    >
      <template #[`item.id`]="{ item }">
        <router-link
          :to="{
            name: 'site-offer-view',
            params: { siteId: siteId, offerId: item.id }
          }"
        >
          {{ item.id }}
        </router-link>
      </template>
      <template #[`item.territories`]="{ item }">
        {{ item.territories.join(", ") }}
      </template>
    </v-data-table>
    <div class="text-center py-2">
      <v-pagination v-model="page" :length="pageCount" :total-visible="11" />
    </div>
  </Page>
</template>

<script>
import Page from "../components/Page"
export default {
  components: { Page },
  props: {
    siteId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      query: "",
      page: 1,
      pageCount: 0,
      totalItems: 0,
      offers: [],
      headers: [
        { text: "ID", value: "id", sortable: false },
        {
          text: "Territories",
          align: "center",
          value: "territories",
          sortable: false
        },
        { text: "Description", value: "description", sortable: false }
      ]
    }
  },
  computed: {
    site() {
      return this.$store.state.sitesMap[this.siteId]
    }
  },
  watch: {
    siteId: "fetch"
  },
  mounted() {
    this.fetch()
  },
  methods: {
    search() {
      this.page = 1
      this.fetch()
    },
    fetch() {
      this.$http
        .get(`api/site/${this.siteId}/offers`, {
          params: {
            page: this.page,
            id: this.query
          }
        })
        .then(response => {
          this.offers = response.data.data.offers
          this.totalItems = response.data.data.totalPages * 10
        })
        .catch(() => {
          this.offers = []
          this.totalItems = 0
        })
    }
  }
}
</script>
