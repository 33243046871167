export default class Promotion {
  constructor() {
    this.name = ""
    this.start = 0
    this.end = 0
    this.territory = ""
    this.url = ""
    this.text = ""
    this.image_url = ""
    this.secret = ""
    this.security = ""
  }
}
