<template>
  <v-col cols="6" sm="3" class="pa-0">
    <v-text-field
      v-model="runtime"
      type="number"
      hint="In minutes"
      :disabled="disabled"
    />
  </v-col>
</template>

<script>
export default {
  name: "RuntimeField",
  props: {
    value: {
      type: Number,
      default: 0
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    runtime: {
      get() {
        return this.value === 0 ? "" : this.value
      },
      set(value) {
        this.$emit("input", parseInt(value) || 0)
      }
    }
  }
}
</script>
