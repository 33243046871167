import store from "./store.js"
import axios from "axios"

const Permission = {
  SiteRead: "site_view",
  SiteEdit: "site_edit",
  SiteCreate: "site_create",
  OfferRead: "offer_view",
  OfferEdit: "offer_edit",
  MetadataRead: "metadata_view",
  MetadataEdit: "metadata_edit"
}

/**
 * @return {null}
 */
async function GetActiveSession() {
  let session = store.state.session

  if (!session) {
    try {
      const response = await axios.get("/api/auth/session", {
        params: {
          _: Date.now()
        }
      })

      if (response.data.status === "ok") {
        session = response.data.data.accessToken
        axios.defaults.headers["X-CSRF-Token"] = response.data.data.csrfToken
        await store.commit("setSession", session)
        await store.commit("setRoles", response.data.data.roles)
      } else {
        return
      }
    } catch (error) {
      return
    }
  }

  if (session.exp < new Date().getTime() / 1000) {
    return
  }

  return session
}

/**
 * @return {boolean}
 */
function HasPermission(permission) {
  if (!permission) {
    return true
  }

  if (!store.state.session) {
    return false
  }

  return store.state.session.role.permissions.includes(permission)
}

/**
 * @return {boolean}
 */
function HasAnyPermission(permissions) {
  if (!permissions || !permissions.length) {
    return true
  }

  if (!store.state.session) {
    return false
  }

  // return true when we find any overlapping permissions
  return (
    store.state.session.role.permissions.filter(perm =>
      permissions.includes(perm)
    ).length > 0
  )
}

export { Permission, GetActiveSession, HasPermission, HasAnyPermission }
