<template>
  <v-navigation-drawer
    v-if="$store.state.session"
    v-model="$store.state.showMenu"
    clipped
    app
  >
    <v-list dense>
      <v-subheader class="ml-2">Sites</v-subheader>

      <v-list-group
        v-for="site in $store.state.sites"
        :key="site.id"
        :group="`/site/${site.id}/`"
      >
        <v-list-item slot="activator" class="px-0">
          <v-list-item-title class="text-wrap">
            <strong>{{ site.name }}</strong>
          </v-list-item-title>
        </v-list-item>

        <v-list-item
          v-if="HasAnyPermission([Permission.OfferEdit, Permission.OfferRead])"
          :to="{ name: 'site-offers-list', params: { siteId: site.id } }"
          class="px-6"
        >
          <v-list-item-action>
            <v-icon>redeem</v-icon>
          </v-list-item-action>

          <v-list-item-title>Offers</v-list-item-title>
        </v-list-item>

        <v-list-item
          v-if="HasAnyPermission([Permission.SiteEdit, Permission.SiteRead])"
          :to="{ name: 'site-regions-list', params: { siteId: site.id } }"
          class="px-6"
        >
          <v-list-item-action>
            <v-icon>language</v-icon>
          </v-list-item-action>

          <v-list-item-title>Regions</v-list-item-title>
        </v-list-item>

        <v-list-item
          v-if="
            HasAnyPermission([Permission.MetadataEdit, Permission.MetadataRead])
          "
          :to="{ name: 'site-contents-list', params: { siteId: site.id } }"
          class="px-6"
        >
          <v-list-item-action>
            <v-icon>movie</v-icon>
          </v-list-item-action>

          <v-list-item-title>Metadata</v-list-item-title>
        </v-list-item>

        <v-list-item
          v-if="HasAnyPermission([Permission.SiteEdit, Permission.SiteRead])"
          :to="{ name: 'site-settings', params: { siteId: site.id } }"
          class="px-6"
        >
          <v-list-item-action>
            <v-icon>settings</v-icon>
          </v-list-item-action>

          <v-list-item-title>Settings</v-list-item-title>
        </v-list-item>
      </v-list-group>

      <div v-if="HasPermission(Permission.SiteCreate)">
        <v-divider></v-divider>

        <v-list-item @click="createSite">
          <v-list-item-title class="py-3">
            <v-icon color="blue" class="mr-2">add_circle</v-icon>
            <span class="overline">Add site</span>
          </v-list-item-title>
        </v-list-item>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { Site } from "./models/site"
import { HasPermission, HasAnyPermission, Permission } from "./auth"

export default {
  data() {
    return {
      sites: [],
      Permission
    }
  },
  beforeMount() {
    this.refresh()
  },
  methods: {
    refresh() {
      this.$store.dispatch("loadSites")
    },
    createSite() {
      let name = prompt("What's the site name?")

      if (name) {
        let site = new Site()
        site.name = name

        this.$http.post("api/site", site).then(response => {
          if (response.status === 201) {
            this.refresh()
            this.showSuccess("Site created")
          }
        })
      }
    },
    HasPermission,
    HasAnyPermission
  }
}
</script>
