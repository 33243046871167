export default class Retailer {
  constructor() {
    this.id = ""
    this.name = ""
    this.logo_url = ""
    this.help_url = ""
    this.redemption_url = ""
    this.requires_exchange = false
    this.active = false
    this.default_suppressed = false
  }
}
