class Artwork {
  constructor() {
    this.language = ""
    this.type = ""
    this.url = ""
  }
}

class Rating {
  constructor() {
    this.region = ""
    this.system = ""
    this.value = ""
  }
}

class LocalizedInfo {
  constructor() {
    this.language = ""
    this.title = ""
    this.artworks = []
    this.genres = []
  }
}

class Content {
  constructor() {
    this.id = null
    this.runtime = 0
    this.content_ids = []
    this.ratings = []
    this.localized_info = []
  }
}

export { Content, LocalizedInfo, Artwork, Rating }
