<template>
  <div>
    <h2>{{ label }}</h2>
    <v-layout v-for="(item, index) in value" :key="index" align-center wrap>
      <v-col cols="12" sm="11" class="px-0 px-sm-3">
        <slot name="item" :index="index" :item="item" />
      </v-col>
      <v-col cols="12" sm="1" class="pt-0 px-0 px-sm-3">
        <v-btn
          v-if="!readonly"
          x-small
          fab
          color="red"
          :title="`Remove this ${name}`"
          @click="removeItem(index)"
        >
          <v-icon small color="white">delete</v-icon>
        </v-btn>
      </v-col>
    </v-layout>

    <v-layout v-if="!readonly" pb-6 class="mt-1">
      <v-flex>
        <v-btn small text class="px-0 px-sm-3" @click="addItem">
          <v-icon>add</v-icon>
          Add {{ name }}
        </v-btn>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: "Multi",
  props: {
    value: {
      type: Array,
      default: () => []
    },
    kind: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    newElement: {
      type: Function,
      default: () => {
        return {}
      }
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    name() {
      return this.kind.toLowerCase()
    }
  },
  methods: {
    addItem() {
      if (!this.value) {
        this.$emit("input", [this.newElement()])
        return
      }

      this.value.push(this.newElement())
    },
    removeItem(index) {
      this.value.splice(index, 1)
    }
  }
}
</script>
