<template>
  <v-app>
    <Navigation />
    <v-app-bar v-if="$store.state.session" clipped-left fixed app dense>
      <v-app-bar-nav-icon @click="toggleMenu" />
      <v-toolbar-title>
        <h1 class="app-name" @click="$router.push({ name: 'home' })">
          MDEO CMS
        </h1>
      </v-toolbar-title>
      <v-spacer />
      <v-menu open-on-hover>
        <template #activator="{ on }">
          <v-chip :pill="$vuetify.breakpoint.xsOnly" outlined v-on="on">
            <span v-if="$vuetify.breakpoint.smAndUp">
              {{ $store.state.session.organization.name }}
            </span>
            <v-avatar size="32" :right="$vuetify.breakpoint.smAndUp">
              <v-img
                v-if="$store.state.session.organization.logo_url"
                :src="$store.state.session.organization.logo_url"
                :alt="$store.state.session.organization.name"
              />
              <v-icon v-else>business</v-icon>
            </v-avatar>
          </v-chip>
        </template>
        <v-list dense>
          <v-list-item>
            <v-list-item-action>
              <v-icon> account_circle </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ $store.state.session.given_name }}
                {{ $store.state.session.family_name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ $store.state.session.email }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-action>
              <v-avatar
                v-if="$store.state.session.organization.logo_url"
                size="24"
              >
                <v-img
                  :src="$store.state.session.organization.logo_url"
                  :alt="$store.state.session.organization.name"
                />
              </v-avatar>
              <v-icon v-else>business</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ $store.state.session.organization.name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ $store.state.session.role.name }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <template v-if="$store.state.roles.length > 1">
            <v-divider />

            <v-subheader>Change Role</v-subheader>

            <v-list-item
              v-for="role in otherRoles"
              :key="role.role.id"
              @click="changeRole(role.role.id)"
            >
              <v-list-item-action>
                <v-avatar v-if="role.organization.logo_url" size="24">
                  <v-img
                    :src="role.organization.logo_url"
                    :alt="role.organization.name"
                  />
                </v-avatar>
                <v-icon v-else>swap_horiz</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ role.organization.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ role.role.name }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>

          <v-divider />

          <v-list-item @click="goToSSO">
            <v-list-item-action>
              <v-icon>apps</v-icon>
            </v-list-item-action>

            <v-list-item-title>Change application</v-list-item-title>
          </v-list-item>

          <v-list-item to="/logout">
            <v-list-item-action>
              <v-icon>lock</v-icon>
            </v-list-item-action>

            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-main>
      <v-container fluid class="pa-0">
        <router-view />
      </v-container>
    </v-main>
    <v-footer fixed app>
      &copy; Thought Foundry {{ new Date().getFullYear() }}
    </v-footer>

    <v-progress-linear
      v-show="activeRequests"
      class="loader-bar"
      indeterminate
      height="5"
    ></v-progress-linear>

    <div v-if="$store.state.alerts" class="errors-container">
      <v-alert
        v-for="alert in $store.state.alerts"
        :key="alert.id"
        :type="alert.level"
        transition="scale-transition"
        dismissible
        prominent
      >
        {{ alert.message }}
      </v-alert>
    </div>
  </v-app>
</template>

<script>
import { mapMutations } from "vuex"

import Navigation from "./Navigation.vue"

export default {
  components: {
    Navigation
  },
  data() {
    return {
      activeRequests: 0,
      drawer: this.$vuetify.breakpoint.mdAndUp
    }
  },
  computed: {
    otherRoles() {
      return this.$store.state.roles.filter(
        r => r.role.id !== this.$store.state.session.role.id
      )
    }
  },
  mounted() {
    this.$http.interceptors.request.use(this.httpRequest, this.httpError)
    this.$http.interceptors.response.use(this.httpResponse, this.httpError)
  },
  methods: {
    ...mapMutations(["toggleMenu"]),
    httpRequest(config) {
      this.activeRequests++
      return config
    },
    httpResponse(response) {
      this.activeRequests--
      if (response.data.status === "error") {
        this.showWarning(response.data.reason)
      }
      return response
    },
    httpError(error) {
      this.activeRequests--
      if (!error.response) {
        this.showError(
          "Unexpected error occurred, please check your internet connection and try again."
        )
        return
      }

      switch (error.response.status) {
        case 401:
          this.showWarning(
            "Your session has expired, please refresh your browser."
          )
          break
        case 403:
          this.showWarning("Insufficient permission to complete your request.")
          break
        default:
          this.showError("Unexpected error occurred, please try again.")
      }
      return Promise.reject(error)
    },
    changeRole(roleId) {
      const baseURL = process.env.VUE_APP_API_URL || "/"

      return window.location.replace(`${baseURL}auth/login?rid=${roleId}`)
    },
    goToSSO() {
      window.location.replace("https://auth.mdeo.co")
    }
  }
}
</script>

<style scoped>
.app-name {
  font-size: 24px;
}
.errors-container {
  position: fixed;
  width: 90%;
  left: 5%;
  min-width: 500px;
  z-index: 1000;
  bottom: 50px;
}
.loader-bar {
  position: fixed;
  width: 100%;
  min-width: 500px;
  z-index: 1000;
  top: 48px;
  left: 0;
}
@media screen and (min-width: 1264px) {
  .errors-container {
    width: 40%;
    left: 30%;
  }

  .app-name {
    font-size: 32px;
  }
}
</style>
