<template>
  <div>
    <v-row v-for="(prop, index) in properties" :key="index">
      <v-col cols="12" sm="10" class="py-0">
        <v-text-field
          v-if="prop.type === 'text'"
          v-model="values[prop.name]"
          :label="`${prop.help} (${prop.name})`"
        />

        <ColorPicker
          v-if="prop.type === 'color'"
          v-model="values[prop.name]"
          :label="`${prop.help} (${prop.name})`"
        />

        <FilePicker
          v-if="prop.type === 'file'"
          v-model="values[prop.name]"
          :label="`${prop.help} (${prop.name})`"
        />

        <ArtworkPicker
          v-if="prop.type === 'artwork'"
          v-model="values[prop.name]"
          :label="`${prop.help} (${prop.name})`"
        />
      </v-col>
      <v-col cols="12" sm="2" class="py-0">
        <v-checkbox v-model="overrides[prop.name]" label="Override" />
        <v-divider v-if="$vuetify.breakpoint.xsOnly" class="mb-4" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import FilePicker from "./FilePicker"
import ColorPicker from "./ColorPicker"
import ArtworkPicker from "./ArtworkPicker"

export default {
  name: "PropertiesValue",
  components: { ArtworkPicker, ColorPicker, FilePicker },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    properties: {
      type: Array,
      default: () => []
    }
  },
  data() {
    let values = {},
      overrides = {}

    ;(this.properties || []).forEach(prop => {
      values[prop.name] = prop.default
      overrides[prop.name] = false
    })
    ;(this.value || []).forEach(val => {
      if (typeof values[val.name] === "string") {
        values[val.name] = val.value
        overrides[val.name] = true
      }
    })

    return {
      values: values,
      overrides: overrides
    }
  },
  watch: {
    overrides: {
      handler(overrides) {
        ;(this.properties || []).forEach(prop => {
          if (!overrides[prop.name]) {
            this.values[prop.name] = prop.default
          }
        })

        this.emitResults()
      },
      deep: true
    },
    values: {
      handler(values) {
        ;(this.properties || []).forEach(prop => {
          if (
            !this.overrides[prop.name] &&
            values[prop.name] !== prop.default
          ) {
            this.overrides[prop.name] = true
          }
        })

        this.emitResults()
      },
      deep: true
    }
  },
  methods: {
    emitResults() {
      let data = []

      ;(this.properties || []).forEach(prop => {
        if (this.overrides[prop.name]) {
          data.push({
            name: prop.name,
            value: this.values[prop.name]
          })
        }
      })

      this.$emit("input", data)
    }
  }
}
</script>
