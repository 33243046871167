class EmailCollectionConfig {
  constructor() {
    this.platform = ""
    this.active = false
    this.secret = ""
  }
}

export default class Region {
  constructor() {
    this.id = null
    this.site_id = null
    this.name = ""
    this.territories = []
    this.links = []
    this.images = []
    this.retailers = []
    this.promotions = []
    this.email_collection_config = new EmailCollectionConfig()
    this.active = false
    this.history = []
  }
}
