<template>
  <Page v-if="site" :title="`${site.name} - Regions`">
    <template slot="actions">
      <v-btn small text color="primary" @click="addRegion">
        <v-icon small left>add</v-icon>
        Add Region
      </v-btn>
    </template>

    <v-container fluid grid-list-lg class="py-6">
      <v-layout row wrap>
        <v-flex v-for="region in regions" :key="region.id" xs12 md4>
          <v-card>
            <v-card-title primary-title>
              <router-link
                :to="{
                  name: 'site-region-view',
                  params: { siteId: siteId, regionId: region.id }
                }"
              >
                <h2>{{ region.name }}</h2>
              </router-link>
            </v-card-title>
            <v-card-text>
              {{ region.retailers ? region.retailers.length : "0" }}
              retailers configured
            </v-card-text>
            <v-card-text>
              <span :class="{ status: true, status_active: region.active }">
                {{ region.active ? "Active" : "Inactive" }}
              </span>
              for
              {{
                (region.territories || ["(no countries set yet)"]).join(", ")
              }}
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </Page>
</template>

<script>
import Region from "../models/region"
import Page from "../components/Page"

export default {
  components: { Page },
  props: {
    siteId: {
      type: String,
      required: true
    }
  },
  computed: {
    site() {
      return this.$store.state.sitesMap[this.siteId]
    },
    regions() {
      return this.$store.state.currentSite.regions
    }
  },
  watch: {
    siteId: "refresh"
  },
  mounted() {
    this.refresh()
  },
  methods: {
    refresh() {
      this.$store.dispatch("loadCurrentSite", this.siteId)
    },
    addRegion() {
      let name = prompt("What's the region name?")

      if (name) {
        let region = new Region()
        region.name = name

        this.$http
          .post(`api/site/${this.siteId}/region`, region)
          .then(response => {
            if (response.status === 201) {
              this.$store.dispatch("loadCurrentSite", {
                siteId: this.siteId,
                force: true
              })
              this.showSuccess("Region created")
              this.$router.push({
                name: "site-region-view",
                params: {
                  siteId: this.siteId,
                  regionId: response.data.data.id
                }
              })
            }
          })
      }
    }
  }
}
</script>

<style>
.status {
  color: gray;
}
.status_active {
  color: green;
}
</style>
