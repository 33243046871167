<template>
  <v-combobox
    v-model="genres"
    label="Genres"
    hint="To add new genre, type its name and press [tab] to complete."
    multiple
    chips
    deletable-chips
    disable-lookup
    append-icon=""
    :disabled="readonly"
  ></v-combobox>
</template>

<script>
export default {
  name: "GenresField",
  props: {
    value: {
      type: Array,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    genres: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      }
    }
  }
}
</script>

<style scoped></style>
