<template>
  <div>
    <v-container fluid grid-list-lg class="pa-0 mt-2">
      <v-layout row wrap>
        <v-flex v-for="(_, index) in region.retailers" :key="index" xs12 md6>
          <v-card>
            <v-card-text>
              <v-text-field
                v-model="region.retailers[index]['name']"
                label="Name"
                hint="Required: The name of the retailer"
                :rules="[rules.required]"
              />

              <v-text-field
                v-model="region.retailers[index]['id']"
                label="ID"
                hint="Required: Retailer ID"
                :rules="[rules.required]"
              />

              <v-text-field
                v-model="region.retailers[index]['logo_url']"
                label="Logo URL"
                hint="Required: Logo URL (must be over HTTPS)"
                :rules="[rules.required]"
              />

              <v-text-field
                v-model="region.retailers[index]['help_url']"
                label="Help URL"
                hint="Help URL (must be over HTTPS)"
                :rules="[]"
              />

              <v-text-field
                v-model="region.retailers[index]['redemption_url']"
                label="Redemption URL"
                hint="Required: Redemption URL (must contain {code} placeholder)"
                :rules="[rules.required]"
              />

              <v-switch
                v-model="region.retailers[index]['default_suppressed']"
                :true-value="false"
                :false-value="true"
                label="Default Display"
              />

              <v-switch
                v-model="region.retailers[index]['requires_exchange']"
                label="Requires Exchange"
              />

              <v-switch
                v-model="region.retailers[index]['active']"
                label="Active"
              />
            </v-card-text>

            <v-card-actions>
              <v-btn
                class="ma-2"
                fab
                small
                color="red"
                @click="deleteRetailer(index)"
              >
                <v-icon color="white">delete</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <v-btn class="my-5" fab small dark color="blue" @click="addRetailer">
      <v-icon>add</v-icon>
    </v-btn>
  </div>
</template>

<script>
import rules from "../rules"
import Retailer from "../models/retailer"
import Region from "../models/region"

export default {
  props: {
    region: {
      type: Object,
      default: () => new Region()
    }
  },
  data() {
    return {
      rules: rules
    }
  },

  methods: {
    addRetailer() {
      if (!this.region.retailers) this.region.retailers = []
      this.region.retailers.push(new Retailer())
    },
    deleteRetailer(index) {
      if (confirm("Are you sure?")) this.region.retailers.splice(index, 1)
    }
  }
}
</script>
