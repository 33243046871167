import Vue from "vue"
import Router from "vue-router"

import Home from "./views/Home.vue"
import Logout from "./views/Logout.vue"
import { GetActiveSession, HasPermission, Permission } from "./auth"
import SiteOffers from "./views/SiteOffers"
import SiteOffer from "./views/SiteOffer"
import SiteSettings from "./views/SiteSettings"
import SiteRegions from "./views/SiteRegions"
import SiteRegion from "./views/SiteRegion"
import SiteContents from "./views/SiteContents"
import SiteContent from "./views/SiteContent"

Vue.use(Router)

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      component: Home
    },
    {
      path: "/site/:siteId/settings",
      name: "site-settings",
      component: SiteSettings,
      props: true,
      meta: {
        requiresPermission: Permission.SiteRead
      }
    },
    {
      path: "/site/:siteId/offers",
      name: "site-offers-list",
      component: SiteOffers,
      props: true,
      meta: {
        requiresPermission: Permission.OfferRead
      }
    },
    {
      path: "/site/:siteId/offer/:offerId",
      name: "site-offer-view",
      component: SiteOffer,
      props: true,
      meta: {
        requiresPermission: Permission.OfferRead
      }
    },
    {
      path: "/site/:siteId/regions",
      name: "site-regions-list",
      component: SiteRegions,
      props: true,
      meta: {
        requiresPermission: Permission.SiteRead
      }
    },
    {
      path: "/site/:siteId/region/:regionId",
      name: "site-region-view",
      component: SiteRegion,
      props: true,
      meta: {
        requiresPermission: Permission.SiteRead
      }
    },
    {
      path: "/site/:siteId/contents",
      name: "site-contents-list",
      component: SiteContents,
      props: true,
      meta: {
        requiresPermission: Permission.MetadataRead
      }
    },
    {
      path: "/site/:siteId/content/:contentId",
      name: "site-content-view",
      component: SiteContent,
      props: true,
      meta: {
        requiresPermission: Permission.MetadataRead
      }
    },
    {
      path: "/logout",
      name: "logout",
      component: Logout
    }
  ]
})

router.beforeEach((to, from, next) => {
  GetActiveSession().then(session => {
    if (!session && to.name !== "logout") {
      let baseURL = process.env.VUE_APP_API_URL || "/"
      window.location = `${baseURL}auth/login?next=${to.path}`
      return
    }

    if (HasPermission(to.meta.requiresPermission)) {
      next()
    } else {
      next({ name: "home" })
    }
  })
})

export default router
