<template>
  <Page v-if="region && site" :title="`${site.name} - Region - ${region.name}`">
    <template slot="actions">
      <v-btn small text color="primary" :disabled="!valid" @click="submit">
        <v-icon small left> save </v-icon>
        Save
      </v-btn>
    </template>

    <v-form ref="form" v-model="valid" lazy-validation class="mt-5">
      <div v-if="!valid" class="my-5 error--text">
        Form validation failed - please check all fields for errors.
      </div>

      <v-text-field
        v-model="region.name"
        label="Name"
        hint="Required: The name of the region"
        :rules="[rules.required]"
      ></v-text-field>

      <v-combobox
        v-model="region.territories"
        label="Country codes (alpha-2)"
        multiple
        small-chips
        :rules="[rules.required, rules.territories]"
      ></v-combobox>

      <v-combobox
        v-model="region.languages"
        label="Supported languages (ISO 639-1)"
        multiple
        small-chips
        :rules="[rules.languages]"
      ></v-combobox>

      <v-switch v-model="region.active" label="Active"></v-switch>

      <h2>Email Collection</h2>

      <v-text-field
        v-model="region.email_collection_config.platform"
        label="Email Platform"
        hint="The platform used with email collection"
        :rules="emailCollectionRules"
      ></v-text-field>

      <v-switch
        v-model="region.email_collection_config.active"
        label="Email Collection Active"
      ></v-switch>

      <v-text-field
        v-model="region.email_collection_config.secret"
        type="password"
        label="Platform Secret"
        hint="Secret Key - set to overwrite"
      ></v-text-field>

      <h2 v-if="site.region_properties">Properties</h2>

      <PropertiesValue
        v-model="region.properties"
        :properties="site.region_properties"
        class="py-4"
      ></PropertiesValue>

      <h2>Retailers</h2>
      <RegionRetailers :region="region" />
    </v-form>
  </Page>
</template>

<script>
import rules from "../rules"
import RegionRetailers from "../components/RegionRetailers"
import PropertiesValue from "../components/PropertiesValue"
import Page from "../components/Page"

export default {
  components: { Page, PropertiesValue, RegionRetailers },
  props: {
    siteId: {
      type: String,
      required: true
    },
    regionId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      region: null,
      valid: false,
      rules: rules
    }
  },
  computed: {
    site() {
      return this.$store.state.sitesMap[this.siteId]
    },
    emailCollectionRules() {
      return this.region.email_collection_config.active
        ? [rules.required]
        : [rules.optional]
    }
  },
  watch: {
    siteId: "refresh"
  },
  mounted() {
    this.refresh()
  },
  methods: {
    refresh() {
      this.$http
        .get(`api/site/${this.siteId}/region/${this.regionId}`)
        .then(response => {
          this.region = response.data.data
        })
    },
    submit() {
      if (!this.$refs.form.validate()) return

      this.$http
        .put(`api/site/${this.siteId}/region/${this.regionId}`, this.region)
        .then(response => {
          if (response.status === 204) {
            this.showSuccess("The region has been updated.")
            this.refresh()
            this.$store.dispatch("loadCurrentSite", {
              siteId: this.siteId,
              force: true
            })
          }
        })
    }
  }
}
</script>
