<template>
  <v-container class="py-0">
    <v-layout row wrap>
      <v-col cols="12" sm="4" class="pa-1">
        <v-text-field
          v-model="rating.region"
          label="Region"
          :disabled="disabled"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4" class="pa-1">
        <v-text-field
          v-model="rating.system"
          label="System"
          :disabled="disabled"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4" class="pa-1">
        <v-text-field
          v-model="rating.value"
          label="Value"
          :disabled="disabled"
        ></v-text-field>
      </v-col>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "RatingField",
  props: {
    value: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    rating: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      }
    }
  }
}
</script>
