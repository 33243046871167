<template>
  <Page v-if="site" :title="`${site.name} - Metadata`">
    <template slot="actions">
      <v-btn small text color="primary" @click="addMetadata">
        <v-icon small left>add</v-icon>
        Add
      </v-btn>
    </template>

    <v-container fluid>
      <v-layout row wrap>
        <v-flex sm10 md8>
          <v-form @submit="submitSearch">
            <v-text-field
              v-model="search"
              label="Search"
              hint="Type search query and press enter to search."
              prepend-inner-icon="search"
              autocomplete="off"
            />
          </v-form>
        </v-flex>
      </v-layout>
    </v-container>

    <v-data-table
      :headers="headers"
      :items="contents"
      hide-default-footer
      class="elevation-1"
    >
      <template #[`item.description`]="{ item }">
        <router-link
          :to="{
            name: 'site-content-view',
            params: { siteId: siteId, contentId: item.id }
          }"
        >
          {{ item.description || item.id }}
        </router-link>
      </template>
      <template #[`item.content_ids`]="{ item }">
        <div v-for="(cid, i) in item.content_ids" :key="`cid-${i}`">
          {{ cid }}
        </div>
      </template>
      <template #[`item.localized_info`]="{ item }">
        {{ item.localized_info.map(x => x.language).join(", ") }}
      </template>
    </v-data-table>
    <div class="text-center py-2">
      <v-pagination v-model="page" :length="pageCount"></v-pagination>
    </div>
  </Page>
</template>

<script>
import Page from "../components/Page"
export default {
  components: { Page },
  props: {
    siteId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      search: this.$route.query.search || "",
      contents: [],
      moreAvailable: false,
      pageCount: 1,
      headers: [
        { text: "Description", value: "description", sortable: false },
        { text: "Content IDs", value: "content_ids", sortable: false },
        { text: "Languages", value: "localized_info", sortable: false }
      ]
    }
  },
  computed: {
    site() {
      return this.$store.state.sitesMap[this.siteId]
    },
    page: {
      get() {
        return parseInt(this.$route.query.page || "1")
      },
      set(value) {
        this.$router.push({
          query: { ...this.$route.query, page: value }
        })
      }
    }
  },
  watch: {
    $route() {
      this.search = this.$route.query.search || ""
      this.load()
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    addMetadata() {
      this.$router.push({
        name: "site-content-view",
        params: { siteId: this.siteId, contentId: "new" }
      })
    },
    submitSearch(e) {
      e.preventDefault()
      this.$router.push({
        query: { ...this.$route.query, search: this.search, page: "1" }
      })
    },
    load() {
      this.$http
        .get(`api/site/${this.siteId}/contents`, {
          params: {
            page: this.page,
            query: this.search
          }
        })
        .then(response => {
          this.contents = response.data.data.contents || []
          this.moreAvailable = response.data.data.moreAvailable
          this.pageCount = this.moreAvailable ? this.page + 1 : this.page
        })
    }
  }
}
</script>
