<template>
  <Page title="Logout Successful">
    <div v-if="$store.state.session">
      <h3>Logging out...</h3>
    </div>
    <div v-if="!$store.state.session">
      <h3>
        You are logged out <small>(<a href="/">login again</a>)</small>
      </h3>
    </div>
  </Page>
</template>

<script>
import Page from "../components/Page"
export default {
  components: { Page },
  mounted() {
    this.logout()
  },
  methods: {
    logout() {
      this.$http
        .get(`auth/logout`, {
          params: {
            _: Date.now()
          }
        })
        .then(() => {
          this.$store.commit("setSession", null)
        })
    }
  }
}
</script>

<style scoped>
small {
  font-size: 1rem;
}
</style>
