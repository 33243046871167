var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.site)?_c('Page',{attrs:{"title":((_vm.site.name) + " - Metadata")}},[_c('template',{slot:"actions"},[_c('v-btn',{attrs:{"small":"","text":"","color":"primary"},on:{"click":_vm.addMetadata}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("add")]),_vm._v(" Add ")],1)],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"sm10":"","md8":""}},[_c('v-form',{on:{"submit":_vm.submitSearch}},[_c('v-text-field',{attrs:{"label":"Search","hint":"Type search query and press enter to search.","prepend-inner-icon":"search","autocomplete":"off"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.contents,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{
          name: 'site-content-view',
          params: { siteId: _vm.siteId, contentId: item.id }
        }}},[_vm._v(" "+_vm._s(item.description || item.id)+" ")])]}},{key:"item.content_ids",fn:function(ref){
        var item = ref.item;
return _vm._l((item.content_ids),function(cid,i){return _c('div',{key:("cid-" + i)},[_vm._v(" "+_vm._s(cid)+" ")])})}},{key:"item.localized_info",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.localized_info.map(function (x) { return x.language; }).join(", "))+" ")]}}],null,true)}),_c('div',{staticClass:"text-center py-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }