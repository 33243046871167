<template>
  <Page
    v-if="site && offer"
    :title="`${site.name} - Offers - ${offer.mdeo_offer.description}`"
  >
    <template slot="actions">
      <v-btn small text color="primary" :disabled="!valid" @click="submit">
        <v-icon small left>save</v-icon>
        Save
      </v-btn>
    </template>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-container fluid grid-list-lg>
        <v-layout row wrap>
          <v-flex xs12>
            <div v-if="!valid" class="my-2 error--text">
              Form validation failed - please check all fields for errors.
            </div>
          </v-flex>

          <v-tabs :mobile-breakpoint="0">
            <v-tab>Details</v-tab>
            <v-tab-item>
              <v-card class="pa-2 mt-5" flat>
                <v-flex xs12>
                  <h3>ID</h3>
                  {{ offer.id }}
                </v-flex>

                <v-flex xs12>
                  <h3>Description</h3>
                  {{ offer.mdeo_offer.description }}
                </v-flex>

                <v-flex xs12>
                  <h3>Territories</h3>
                  {{ offer.mdeo_offer.territories.join(", ") }}
                </v-flex>
              </v-card>
            </v-tab-item>

            <v-tab>Metadata</v-tab>
            <v-tab-item>
              <SiteOfferMetadataForm
                v-if="metadata && metadata.length > 0"
                v-model="metadata"
                :site-id="siteId"
                :offer="offer"
              />
              <v-container v-else>
                <v-row>
                  <v-col>
                    <p>
                      There are no regions with languages setup for the
                      following territories:
                      {{ offer.mdeo_offer.territories.join(", ") }}.
                    </p>

                    <router-link
                      :to="{
                        name: 'site-regions-list',
                        params: { siteId: siteId }
                      }"
                    >
                      <p>Update Regions</p>
                    </router-link>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>

            <v-tab v-if="site.offer_properties">Properties</v-tab>
            <v-tab-item v-if="site.offer_properties">
              <v-card class="px-4 mt-4" flat>
                <PropertiesValue
                  v-model="offer.properties"
                  :properties="site.offer_properties"
                ></PropertiesValue>
              </v-card>
            </v-tab-item>

            <v-tab>Retailers</v-tab>

            <v-tab-item>
              <v-card class="px-2 my-4" flat>
                <v-flex xs12>
                  <h2>Display</h2>
                </v-flex>

                <v-flex
                  v-for="territory in offer.mdeo_offer.territories"
                  :key="territory"
                  xs12
                  sm6
                  md4
                  lg3
                  xl2
                >
                  <v-card>
                    <v-card-title primary-title>
                      <h3>{{ territory }}</h3>
                    </v-card-title>

                    <v-card-text>
                      <v-switch
                        v-for="retailer in retailersPerTerritory[territory]"
                        :key="retailer.id"
                        :input-value="
                          !retailerSuppression[territory + ':' + retailer.id]
                        "
                        :label="retailer.name"
                        @change="toggleSuppression(territory, retailer.id)"
                      ></v-switch>
                      <div v-if="!retailersPerTerritory[territory]">
                        <p>There are no retailers set up for this territory</p>
                        <router-link
                          :to="{
                            name: 'site-regions-list',
                            params: { siteId: siteId }
                          }"
                        >
                          <p>Update Regions</p>
                        </router-link>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-flex>
              </v-card>

              <v-card class="px-2 my-4" flat>
                <v-flex v-if="exchangeRetailers.length" xs12>
                  <h2>Contains Native Codes</h2>
                </v-flex>

                <v-flex v-if="exchangeRetailers.length" xs12 sm6 md4 lg3 xl2>
                  <v-card>
                    <v-card-text>
                      <v-switch
                        v-for="retailer in exchangeRetailers"
                        :key="retailer.id"
                        :input-value="offer.native_retailer === retailer.id"
                        :label="retailer.name"
                        @change="toggleNativeRetailer(retailer.id)"
                      >
                      </v-switch>
                    </v-card-text>
                  </v-card>
                </v-flex>
              </v-card>
            </v-tab-item>

            <v-tab>Promotions</v-tab>
            <v-tab-item>
              <v-card class="px-2 mt-4" flat>
                <v-flex xs12>
                  <h2>Promotions</h2>
                </v-flex>

                <v-flex
                  v-for="(_, index) in offer.promotions"
                  :key="`promo-${index}`"
                  xs12
                >
                  <v-card>
                    <v-card-text>
                      <v-text-field
                        v-model="offer.promotions[index].name"
                        label="Name"
                        hint="Promotion name"
                        :rules="[rules.required]"
                      ></v-text-field>

                      <DateTimePicker
                        v-model="offer.promotions[index].start"
                        label="Start"
                      ></DateTimePicker>

                      <DateTimePicker
                        v-model="offer.promotions[index].end"
                        label="End"
                      ></DateTimePicker>

                      <v-select
                        v-model="offer.promotions[index].territory"
                        :items="offer.mdeo_offer.territories"
                        label="Territory"
                        :rules="[rules.required]"
                      ></v-select>

                      <v-text-field
                        v-model="offer.promotions[index].url"
                        label="URL"
                        hint="URL where user will be redirected when clicking on the promotion"
                        :rules="[rules.required]"
                      ></v-text-field>

                      <v-text-field
                        v-model="offer.promotions[index].text"
                        label="Alternative text"
                        hint="Alternative text (used by screen readers)"
                        :rules="[rules.required]"
                      ></v-text-field>

                      <FilePicker
                        v-model="offer.promotions[index].image_url"
                        label="Image URL"
                      ></FilePicker>

                      <v-select
                        v-model="offer.promotions[index].security"
                        label="Security"
                        :items="promotionSecurity"
                      />

                      <v-text-field
                        v-if="offer.promotions[index].security !== ''"
                        v-model="offer.promotions[index].secret"
                        :label="
                          promotionLabel(offer.promotions[index].security)
                        "
                        :hint="promotionHint(offer.promotions[index].security)"
                      ></v-text-field>

                      <h2 v-if="site.promo_properties">Properties</h2>
                      <PropertiesValue
                        v-model="offer.promotions[index].properties"
                        :properties="site.promo_properties"
                      ></PropertiesValue>

                      <v-btn
                        small
                        fab
                        color="red"
                        @click="removePromotion(index)"
                      >
                        <v-icon color="white">delete</v-icon>
                      </v-btn>
                    </v-card-text>
                  </v-card>
                </v-flex>

                <v-btn
                  class="ma-2 mb-4"
                  small
                  fab
                  color="primary"
                  @click="addPromotion"
                >
                  <v-icon>add</v-icon>
                </v-btn>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-layout>
      </v-container>
    </v-form>
  </Page>
</template>

<script>
import DateTimePicker from "../components/DateTimePicker"
import FilePicker from "../components/FilePicker"
import PropertiesValue from "../components/PropertiesValue"
import Promotion from "../models/promotion"
import rules from "../rules"
import Page from "../components/Page"
import SiteOfferMetadataForm from "../components/SiteOfferMetadataForm"

export default {
  components: {
    SiteOfferMetadataForm,
    Page,
    DateTimePicker,
    FilePicker,
    PropertiesValue
  },
  props: {
    siteId: {
      type: String,
      required: true
    },
    offerId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      valid: false,
      offer: null,
      metadata: [],
      rules: rules,
      retailerSuppression: {},
      promotionSecurity: [
        { text: "Public", value: "" },
        { text: "Dynamic Fanflix Key", value: "fanflix" },
        { text: "Code Pass-Through", value: "pass-through" }
      ]
    }
  },
  computed: {
    site() {
      return this.$store.state.sitesMap[this.siteId]
    },
    retailersPerTerritory() {
      return this.$store.getters.retailersPerTerritory
    },
    exchangeRetailers() {
      let exchRetailers = {}
      Object.keys(this.retailersPerTerritory).forEach(territory => {
        if (this.retailersPerTerritory[territory]) {
          this.retailersPerTerritory[territory]
            .filter(retailer => retailer.requires_exchange)
            .forEach(retailer => {
              exchRetailers[retailer.id] = retailer
            })
        }
      })
      return Object.keys(exchRetailers)
        .map(retailerId => exchRetailers[retailerId])
        .sort()
    }
  },
  watch: {
    siteId: "fetch",
    offerId: "fetch"
  },
  mounted() {
    this.fetch()
  },
  methods: {
    fetch() {
      this.$http
        .get(`api/site/${this.siteId}/offer/${this.offerId}`)
        .then(response => {
          let offer = response.data.data.offer
          offer.retailer_suppression = offer.retailer_suppression || []
          offer.properties = offer.properties || []
          offer.promotions = offer.promotions || []
          this.$set(this, "offer", offer)

          this.$set(this, "metadata", response.data.data.metadata)

          this.$set(this, "retailerSuppression", {})
          this.offer.retailer_suppression.forEach(rs => {
            this.$set(
              this.retailerSuppression,
              `${rs.territory}:${rs.retailer_id}`,
              true
            )
          })
        })
      this.$store.dispatch("loadCurrentSite", this.siteId)
    },
    submit() {
      if (!this.$refs.form.validate()) return

      let suppression = []
      this.offer.mdeo_offer.territories.forEach(territory => {
        if (this.retailersPerTerritory[territory]) {
          this.retailersPerTerritory[territory].forEach(retailer => {
            if (this.retailerSuppression[territory + ":" + retailer.id])
              suppression.push({
                territory: territory,
                retailer_id: retailer.id
              })
          })
        }
      })
      this.$set(this.offer, "retailer_suppression", suppression)

      let payload = {
        offer: this.offer,
        metadata: this.metadata
      }
      this.$http
        .put(`api/site/${this.siteId}/offer/${this.offerId}`, payload)
        .then(response => {
          if (response.status === 204) {
            this.showSuccess("The offer has been updated.")
          }
        })
    },
    toggleSuppression(territory, retailerId) {
      let key = `${territory}:${retailerId}`
      this.retailerSuppression[key] = !this.retailerSuppression[key]
    },
    toggleNativeRetailer(retailerId) {
      let nativeRetailer
      if (
        this.offer.native_retailer &&
        this.offer.native_retailer === retailerId
      ) {
        nativeRetailer = null
      } else {
        nativeRetailer = retailerId
      }
      this.$set(this.offer, "native_retailer", nativeRetailer)
    },
    addPromotion() {
      this.offer.promotions.push(new Promotion())
    },
    removePromotion(index) {
      if (confirm("Are you sure?")) {
        this.offer.promotions.splice(index, 1)
      }
    },
    promotionLabel(security) {
      if (security === "fanflix") return "Secret"

      if (security === "pass-through") return "Code Validation URL"

      return ""
    },
    promotionHint(security) {
      if (security === "fanflix")
        return "Fanflix Checkout Secret - a time limited URL signature will be calculated when set"

      if (security === "pass-through")
        return "URL used to validate pass-through code before promotion can be returned (use {code} placeholder)"

      return ""
    }
  }
}
</script>
