<template>
  <div>
    <v-text-field v-model="li.title" label="Title" />

    <GenresField v-model="li.genres" />

    <ArtworksField v-model="li.artworks" required />
  </div>
</template>

<script>
import { Artwork } from "@/models/content"
import ArtworksField from "./ArtworksField"
import GenresField from "./GenresField"

export default {
  name: "LocalizedInfoForm",
  components: { GenresField, ArtworksField },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      Artwork,
      artworkTypes: ["poster", "banner", "hero"]
    }
  },
  computed: {
    li: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      }
    }
  }
}
</script>

<style scoped></style>
