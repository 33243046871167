<template>
  <Page
    v-if="content && site"
    :title="`${site.name} - Metadata - ${contentTitle}`"
  >
    <template slot="actions">
      <v-btn small text color="primary" :disabled="!valid" @click="submit">
        <v-icon small left> save </v-icon>
        Save
      </v-btn>
    </template>

    <v-form ref="form" v-model="valid" lazy-validation class="mt-5">
      <div v-if="!valid" class="my-5 error--text">
        Form validation failed - please check all fields for errors.
      </div>

      <h2>Description</h2>
      <v-text-field v-model="content.description" :rules="[rules.required]" />

      <Multi
        v-model="content.content_ids"
        kind="Content ID"
        label="Content IDs"
        :new-element="() => ''"
      >
        <template #item="{ index }">
          <v-text-field
            v-model="content.content_ids[index]"
            :rules="[rules.required]"
            placeholder="10.5240/XXXX-XXXX-XXXX-XXXX-XXXX-C"
          ></v-text-field>
        </template>
      </Multi>

      <Multi
        v-model="content.ratings"
        kind="Rating"
        label="Ratings"
        :new-element="() => new Rating()"
      >
        <template #item="{ index }">
          <RatingField v-model="content.ratings[index]" />
        </template>
      </Multi>

      <h2>Runtime</h2>
      <RuntimeField v-model="content.runtime" />

      <h2>Localization</h2>

      <v-btn small text class="my-2" @click="addLanguage">
        <v-icon>add</v-icon>
        Add Language
      </v-btn>
      <v-btn
        v-if="languageTab >= 0"
        small
        absolute
        text
        right
        class="my-2"
        color="red"
        @click="removeLanguage(languageTab)"
      >
        <v-icon small left> delete </v-icon>
        Delete Language
      </v-btn>

      <v-layout>
        <v-col cols="4" sm="3" md="2" lg="1" class="pa-0 pr-2">
          <v-tabs v-model="languageTab" vertical grow>
            <v-tab
              v-for="(li, i) in content.localized_info"
              :key="`tabName-${i}`"
              >{{ li.language }}</v-tab
            >
          </v-tabs>
        </v-col>
        <v-col cols="8" sm="9" md="10" lg="11" class="pa-0 pl-2">
          <v-tabs-items v-model="languageTab" vertical>
            <v-tab-item
              v-for="(_, i) in content.localized_info"
              :key="`tab-item-${i}`"
            >
              <LocalizedInfoForm
                v-model="content.localized_info[i]"
              ></LocalizedInfoForm>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-layout>
    </v-form>
  </Page>
</template>

<script>
import rules from "../rules"
import { Content, LocalizedInfo, Rating } from "@/models/content"
import Page from "../components/Page"
import Multi from "../components/Multi"
import RatingField from "../components/RatingField"
import LocalizedInfoForm from "../components/LocalizedInfoForm"
import RuntimeField from "../components/RuntimeField"

export default {
  components: { RuntimeField, LocalizedInfoForm, RatingField, Multi, Page },
  props: {
    siteId: {
      type: String,
      required: true
    },
    contentId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      Rating,
      content: null,
      valid: false,
      rules: rules,
      languageTab: 0
    }
  },
  computed: {
    site() {
      return this.$store.state.sitesMap[this.siteId]
    },
    contentTitle() {
      if (!this.content.id) {
        return "New Content"
      }

      if (this.content.localized_info.length > 0) {
        return this.content.localized_info[0].title
      }

      return this.content.id
    }
  },
  watch: {
    siteId: "refresh"
  },
  mounted() {
    this.refresh()
  },
  methods: {
    addLanguage() {
      let lang = ""
      let validLanguageCode = null
      while (validLanguageCode === null) {
        lang = prompt("Provide a valid language code ('en' format):", lang)

        if (!lang) return

        if (!lang.match(/^[a-z]{2}$/)) {
          continue
        }

        validLanguageCode = lang
      }

      let li = new LocalizedInfo()
      li.language = validLanguageCode
      this.content.localized_info.push(li)
      this.languageTab = this.content.localized_info.length - 1
    },
    removeLanguage(index) {
      const lang = this.content.localized_info[index].language.toUpperCase()
      const confirmText = `Are you sure you want to delete '${lang}'?`
      if (!confirm(confirmText)) return

      this.content.localized_info.splice(index, 1)
    },
    refresh() {
      if (this.contentId === "new") {
        this.content = new Content()
        this.content.content_ids.push("")
        return
      }

      this.$http
        .get(`api/site/${this.siteId}/content/${this.contentId}`)
        .then(response => {
          this.content = response.data.data
        })
    },
    submit() {
      if (!this.$refs.form.validate()) return

      if (this.content.id) {
        this.submitExistingContent()
      } else {
        this.submitNewContent()
      }
    },
    submitNewContent() {
      this.$http
        .post(`api/site/${this.siteId}/content`, this.content)
        .then(response => {
          if (response.status === 201 && response.data.status === "ok") {
            this.showSuccess("New content has been created.")
            this.content = response.data.data
            this.$router.push({
              name: "site-content-view",
              params: { siteId: this.siteId, contentId: response.data.data.id }
            })
          }
        })
    },
    submitExistingContent() {
      this.$http
        .put(`api/site/${this.siteId}/content/${this.content.id}`, this.content)
        .then(response => {
          if (response.status === 204) {
            this.showSuccess("The content has been updated.")
          }
        })
    }
  }
}
</script>
